import types from "./spendingDemand.types";

import {
  FilterSpendingDemand,
  SpendingDemand,
} from "../types/spendingDemand.types";

export const setFilterSpendindDemandAction = (
  filter: FilterSpendingDemand | null
) => ({ type: types.SET_FILTER, payload: filter });

export const setSpendingDemandSelectedListAction = (
  list: SpendingDemand[] | null
) => ({ type: types.SET_SPENDING_DEMAND_SELECTED_LIST, payload: list });

export const setSelectModeAction = (selectMode: boolean) => ({
  type: types.SET_SELECT_MODE,
  payload: selectMode,
})