/*================================================================
 *ListBoxItem
 *================================================================*/

import React from "react";

import styles from "./ListBoxItem.module.scss";

import arrowSVG from "./assets/arrow.svg";
import { Link } from "react-router-dom";
import CheckCase from "../../atoms/CheckCase/CheckCase";
export interface ListBoxItemProps {
  titleLeft?: string;
  titleRight?: string;
  subTitleRight?: string;
  subTitleLeft?: string;
  headerTitleLeft?: string;
  headerTitleRight?: string;
  spanTitleLeft?: string;
  spanTitleRight?: string;
  withIconArrowRight?: boolean;
  handleClick?: () => void;
  linkTo?: string;
  ButtomComponent?: React.ComponentType;
  titleSize?: string;
  lableSize?: string;
  titleLabelRight?: string;
  titleLabelLeft?: string;
  pinColor?: string;
  withPin?: boolean;
  colorSubTitleLeft?: {backgroundColor?: string; color?: string}
  colorSubTitleRight?: {backgroundColor?: string; color?: string};
  withCheckCase?: boolean;
  isChecked?: boolean;
  onCheck?: () => void;
}

function ListBoxItem({
  titleLeft,
  ButtomComponent,
  linkTo,
  withIconArrowRight,
  titleSize,
  titleRight,
  subTitleLeft,
  spanTitleLeft,
  spanTitleRight,
  subTitleRight,
  headerTitleLeft,
  headerTitleRight,
  lableSize,
  titleLabelLeft,
  titleLabelRight,
  pinColor,
  withPin,
  colorSubTitleLeft,
  colorSubTitleRight,
  withCheckCase,
  isChecked,
  onCheck,
}: ListBoxItemProps) {
  const titleStyle = {
    fontSize: titleSize,
  };

  const labelStyle = {
    fontSize: lableSize,
  };
  const pinStyle = {
    backgroundColor: pinColor,
  };
  const content = (
    <>
      {withPin && <div className={styles["pin"]} style={pinStyle} />}

      <div className={styles["content"]}>
        <div className={styles["content-body"]}>
        {withCheckCase &&
            <div
              className={[
              styles["check-case"]
            ].join(" ")}>
              <CheckCase isChecked={isChecked} onClick={(e) =>{e.stopPropagation(); onCheck && onCheck()}} />
            </div>
      } 
          <div
            className={[
              styles["content-container"],
              styles["content-container--left"],
            ].join(" ")}
          >
            {(headerTitleLeft || headerTitleRight) && (
              <h3
                className={[
                  styles["content-container__header-title"],
                  styles["content-container__header-title--left"],
                ].join(" ")}
              >
                {headerTitleLeft}
              </h3>
            )}

            {titleLeft && (
              <div className={styles["title-container"]}>
                <h2
                  style={titleStyle}
                  className={[
                    styles["title-container__title"],
                    styles["title-container__title--left"],
                  ].join(" ")}
                >
                  {titleLeft}
                </h2>
                <span
                  className={[
                    styles["title-container__label"],
                    styles["title-container__label--left"],
                  ].join(" ")}
                  style={labelStyle}
                >
                  {titleLabelLeft}
                </span>
              </div>
            )}
            {subTitleLeft && (
              <h3
                style={{ ...colorSubTitleLeft }}
                className={[
                  styles["content-container__sub-title"],
                  styles["content-container__sub-title--left"],
                  colorSubTitleLeft ? styles["content-container__sub-title--with-bg"] : '',
                ].join(" ")}
              >
                {subTitleLeft}
              </h3>
            )}
          </div>

          <div
            className={[
              styles["content-container"],
              styles["content-container--right"],
            ].join(" ")}
          >


            {(headerTitleLeft || headerTitleRight) && (
              <h3
                className={[
                  styles["content-container__header-title"],
                  styles["content-container__header-title--right"],
                ].join(" ")}
              >
                {headerTitleRight}
              </h3>
            )}

            {titleRight && (
              <div
                className={[
                  styles["title-container"],
                  styles["title-container--right"],
                ].join(" ")}
              >
                <h2
                  style={titleStyle}
                  className={[
                    styles["title-container__title"],
                    styles["title-container__title--right"],
                  ].join(" ")}
                >
                  {titleRight}
                </h2>
                <span
                  className={[
                    styles["title-container__label"],
                    styles["title-container__label--right"],
                  ].join(" ")}
                  style={labelStyle}
                >
                  {titleLabelRight}
                </span>
              </div>
            )}
            {subTitleRight && (
              <div
            
                className={[
                  styles["content-container__sub-title"],
                  styles["content-container__sub-title--right"],
                  colorSubTitleRight?.backgroundColor !== undefined ? styles["content-container__sub-title--with-bg"] : '',
                ].join(" ")}
              >
                <h3     style={{ ...colorSubTitleRight }} className={[styles["content-container__sub-title__text"],styles["content-container__sub-title__text--right"], colorSubTitleRight?.backgroundColor !== undefined ? styles["content-container__sub-title__text--with-bg"]:  ""].join(" ")}>{subTitleRight}</h3>
              </div>
            )}
          </div>
        </div>
        {ButtomComponent && (
          <div className={styles["bottom-content"]}>
            <ButtomComponent />
          </div>
        )}
      </div>
      {withIconArrowRight && (
        <div className={styles["right-btn"]}>
          <img src={arrowSVG} />
        </div>
      )}
    </>
  );

  if (linkTo !== undefined) {
    return (
      <Link
        to={linkTo}
        className={[
          styles["list-box-item"],
          styles["list-box-item--clickable"],
        ].join(" ")}
      >
        {content}
      </Link>
    );
  }

  return <div className={styles["list-box-item"]}>{content}</div>;
}

export default ListBoxItem;
