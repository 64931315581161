/*===================================================================
=      Spending Demand Reducer                       =
====================================================================*/
/**
 * Spending Demand Reducer
 */

import { FilterSpendingDemand, SpendingDemand } from "../types/spendingDemand.types";

import types from "./spendingDemand.types"

export interface ISpendingDemandState{
    filter:  FilterSpendingDemand| null;
    spendingDemandSelected: SpendingDemand[] | null;
    isSelectMode: boolean
}

//=============== INITIAL STATE ==================
const initialState: ISpendingDemandState = {
    filter: null,
    spendingDemandSelected: null,
    isSelectMode: false
}

//=============== RDUCER ==================
const spendingDemandReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.SET_FILTER :
            return {
                ...state,
                filter: action.payload
            }
        case types.SET_SPENDING_DEMAND_SELECTED_LIST :
            return {
                ...state,
                spendingDemandSelected: action.payload
            }
        case types.SET_SELECT_MODE :
            return {
                ...state,
                isSelectMode: action.payload
            }
        default:
            return state
    }
}

export default spendingDemandReducer