/*===================================================================
=    Spending DEMAND API                     =
====================================================================*/

import axios from "axios";

import { generateURLQuery } from "../../../utils/urlQueryGenerator";
import { ResponseAPIWithPagination } from "../../../types/responsesAPI.types";
import { FilterSpendingDemand, SpendingDemandExtended } from "../types/spendingDemand.types";
import { FilterpendingDemandConverterToQuery } from "../utils/filterConverter";
import { get } from "http";
import { SpendingDemandHistoricStatus } from "../types/spendingDemandHistoricStatut.types";

/**
 * Sending demand api request
 * 
 * All API protected required the use call of 
 */
const BASE_URL = process.env.REACT_APP_URL_BACKEND



export function fetchAllSpendingDemandsAPIProtectedV2(pageSize: number | null, page: number | null,userId: string | undefined, filterOptions?: FilterSpendingDemand | null) {

  console.log(filterOptions)
  const {queryArray} = FilterpendingDemandConverterToQuery(filterOptions) 

  const queryList = [
    {
      key: "pageSize",
      value: pageSize
    },
    {
      key: "currentPage",
      value: page
    },
    {
      key: "extendedfields",
      value: "user,project"
    },
    {
      key: "managerEmployeeId",
      value: userId
    },
    ...queryArray
  ]


  
  const query = generateURLQuery(queryList)

  console.log('query='+query)

  

  
  return axios.get<ResponseAPIWithPagination<SpendingDemandExtended>>(`${BASE_URL}/api/v2/spendingdemands${query}`);
}

export function fetchSingleSpendingDemandAPIProtectedV2(spendingDemandId: string, managerEmployeeId?: string) {
  return axios.get<SpendingDemandExtended>(`${BASE_URL}/api/v2/spendingdemands/${spendingDemandId}`+
  "?extendedfields=user,project"
  );
}

export function fecthAllSpendingDemandOfUserAPIProtectedV2(pageSize: number | null, page: number | null, userId: string) {
  const query = generateURLQuery([
    {
      key: "pageSize",
      value: pageSize
    },
    {
      key: "currentPage",
      value: page
    },
    {
      key: "extendedfields",
      value: "project"
    }
  ])
  return axios.get<ResponseAPIWithPagination<SpendingDemandExtended>>(`${BASE_URL}/api/v2/users/${userId}/spending_demands${query}`);
}

export const validateSpendingDemandRequest = async (spendingDemandId: string, message?: string) => {
  return await axios.post(`${BASE_URL}/api/v2/spendingdemands/${spendingDemandId}/validate`, {message})
}

export const askForUpdateSpendingDemandRequest = async (spendingDemandId: string, message: string) => {
  return await axios.post(`${BASE_URL}/api/v2/spendingdemands/${spendingDemandId}/ask_for_update`, {message})
}

export const refuseSpendingDemandRequest = async (spendingDemandId: string, message: string) => {
  return await axios.post(`${BASE_URL}/api/v2/spendingdemands/${spendingDemandId}/refuse`, {message})
}

const getFetchSingleSpendingDemandAPIProtectedV2 = async (spendingDemandId: string, managerEmployeeId?: string) => {
    return (await fetchSingleSpendingDemandAPIProtectedV2(spendingDemandId, managerEmployeeId)).data;
}

const validateSpendingDemandAPIProtected = async (spendingDemandId: string, message?: string) => {
  return (await validateSpendingDemandRequest(spendingDemandId, message)).data
}

const askForUpdateSpendingDemandAPIProtected = async (spendinDemandId: string, message: string) => {
  return (await  askForUpdateSpendingDemandRequest(spendinDemandId, message) ).data
}

const refuseSpendingDemandAPIProtected = async (spendinDemandId: string, message: string) => {
  return (await refuseSpendingDemandRequest(spendinDemandId, message)).data
}

const getFetchAllSpendingDemand = async (pageSize:number | null ,page: number, filter: FilterSpendingDemand | null | undefined, userId: string, forUser: boolean ) => {
  if(forUser) {
    return (await fecthAllSpendingDemandOfUserAPIProtectedV2(pageSize || 8, page, userId)).data
  }else{
    return (await fetchAllSpendingDemandsAPIProtectedV2(pageSize || 8, page,userId, filter)).data
  }
  //TODO : PUT the Right userId

}

const getFetchHistoricStatusSpendingDemand = async (spendingDemandId: string) => {
  return (await (axios.get<ResponseAPIWithPagination<SpendingDemandHistoricStatus>>(`${BASE_URL}/api/v2/spendingdemands/${spendingDemandId}/status_historics?extendedfields=user`))).data
}


export default {
    getFetchSingleSpendingDemandAPIProtectedV2,
    validateSpendingDemandAPIProtected,
    askForUpdateSpendingDemandAPIProtected,
    refuseSpendingDemandAPIProtected,
    getFetchAllSpendingDemand,
    getFetchHistoricStatusSpendingDemand
}