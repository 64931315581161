import React from "react";
import ListBoxItem from "../../../../../../components/molecules/ListBoxItem/ListBoxItem";
import { numberToStingWithSpaces } from "../../../../../../utils/numberDisplayer";
import "./spendingDemandListView.scss";
import EmptyListIcon from "../../../../../../components/atoms/EmptyListIcon/EmptyListIcon";
import Table from "../../../../../../components/molecules/Table/Table";
import StatusBox from "../../../../../../components/atoms/StatusBox/StatusBox";
import { IconType } from "../../../../../../assets/JWayIcons";
export interface ItemSpending {
  id: string;
  title?: string;
  userFirstName?: string;
  userLastName?: string;
  date?: string;
  createdDate?: string;
  grossAmmount?: number;
  status?: string;
  pinColor?: string;
  withPin?: boolean;
  isChecked?: boolean;
  colorStatus?: {
    backgroundColor?: string;
    color?: string;
    iconType?: IconType;
  };


  onCheck?: () => void;
  onClick?: () => void;
}
export interface SpendingDemandListViewProps {
  spendingList: ItemSpending[];
  emptyMessage?: string;
  isCheckMode?: boolean;
}

export const SpendingDemandListViewV2 = ({
  spendingList,
  emptyMessage,
  isCheckMode,
}: SpendingDemandListViewProps) => {
  return (
    <div>
      <Table
        emptyMessage={'Aucune demande de dépense'}
        withCheckCase={isCheckMode}
        headerTitle={[
          "Description",
          "Créer le",
          "Montant",
          "utilisateur",
          "statut",
        ]}
        bodyData={spendingList.map((spending) => ({
          id: spending.id,
          onRowClick: spending.onClick,
          onCheck: spending.onCheck,
          isChecked: spending.isChecked,
          data: [
            spending.title,
            spending.createdDate,
            numberToStingWithSpaces(spending.grossAmmount || 0),
            spending.userFirstName + " " + spending.userLastName,
            () => (

              <StatusBox
              
                color={{
                  background: spending.colorStatus?.backgroundColor,
                  text: spending.colorStatus?.color,
                }}
               iconType={ spending.colorStatus?.iconType}
                size={{
                    height: "20px",
                    font: "0.7rem",
                    width: "100px",
                    lineHeight: "0.8rem"
                    
                    
                }}
              >
                {spending.status}
              </StatusBox>
            ),
          ],
        }))}
      />
    </div>
  );
};

export function SpendingDemandListView({
  spendingList,
  emptyMessage,
  isCheckMode,
}: SpendingDemandListViewProps) {
  if (emptyMessage && spendingList.length === 0) {
    return <EmptyListIcon message={emptyMessage} marginTop={"80px"} />;
  }
  return (
    <ul className="spending-list">
      {spendingList.map((spending, index) => (
        <li className="spending-list-item" key={index}>
          <ListBoxItem
            titleSize="1.1rem"
            headerTitleLeft={spending.createdDate}
            titleLeft={spending.title}
            subTitleLeft={spending.userFirstName + " " + spending.userLastName}
            titleRight={
              "- " + numberToStingWithSpaces(spending.grossAmmount || 0)
            }
            titleLabelRight="FCFA"
            subTitleRight={spending.status}
            pinColor={spending.pinColor}
            withPin={spending.withPin}
            linkTo={
              !isCheckMode ? "/spending_demand/" + spending.id : undefined
            }
            handleClick={isCheckMode ? spending.onCheck : undefined}
            colorSubTitleRight={spending.colorStatus}
            withCheckCase={isCheckMode}
            isChecked={spending.isChecked}
            onCheck={spending.onCheck}
          />
        </li>
      ))}
    </ul>
  );
}

export default SpendingDemandListViewV2;
