import GeneralInfoView from './GeneralInfoView/GeneralInfoView'
import { dateConverter } from '../../../../../utils/dateConverter'
import { useSingleSpendingDemand } from '../../../hooks'
import { numberToStingWithSpaces } from '../../../../../utils/numberDisplayer'
import { spendingDemandStatusRawToReadableStatus } from '../../../utils/spenpdingDemandStatusConverter'




const UNIT = "FCFA"



export default function GeneralInfoPresenter() {

  const {singleSpendingDemandData:singleSpending, isLoading, managerWaitingValidation } = useSingleSpendingDemand()
  


  const userName = singleSpending?.spending_demand_user?.username
  const userLastName = singleSpending?.spending_demand_user?.lastname
  const projectName = singleSpending?.spending_demand_project?.project_name


  const files = singleSpending?.spending_demand_files

  const urlBackend = process.env.REACT_APP_URL_BACKEND+"/photos/single/"

  const listManagerWaitingValisation = managerWaitingValidation.data



  
  

  const filesformated = files?.map((file, index) => {
    return {
      src: urlBackend+file.filename,
      filename: "fichier"+index+".jpeg",
      filetype: "image/jpeg",
    }
  })

  


  if(isLoading) return <div>Chargement ...</div>
  if(!singleSpending) return <div>Désolé, Une erreur est survenue</div>

  const colorStatus = spendingDemandStatusRawToReadableStatus(
    singleSpending?.spending_demand_status || "",
    "for-user"
  ).color

  return (<GeneralInfoView
      amount={ numberToStingWithSpaces(singleSpending?.spending_demand_amountTTC || 0)+' '+UNIT}
      description={singleSpending?.spending_demand_desc}
      paymentDate={singleSpending?.spending_demand_date && dateConverter(singleSpending?.spending_demand_date)}
      updateAtDate={singleSpending?.spending_demand_update_date && dateConverter(singleSpending?.spending_demand_date)}
      userName={userName}
      userLastname={userLastName}
      projectName={projectName}
      statut={singleSpending ? spendingDemandStatusRawToReadableStatus(singleSpending?.spending_demand_status,"general",singleSpending?.is_to_process ).status : undefined}
      remak={singleSpending?.spending_demand_remarks}
      filesList={filesformated}
      managerWaitingValidation={listManagerWaitingValisation.map((manager) => ({ firstname: manager?.username, lastname: manager?.lastname}))}
      colorStatus={{backgroundColor: colorStatus.light, color: colorStatus.dark}}
    />)
}


