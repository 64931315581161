import React from "react";
import SpendingDemandListView, {
  ItemSpending,
} from "./SpendingDemandListView/SpendingDemandListView";

import { dateConverter } from "../../../../../utils/dateConverter";

import { useSpendingDemand } from "../../../hooks";
import Pagination from "../../../../../components/organismes/Pagination/Pagination";
import { ReadableStatus, spendingDemandStatusRawToReadableStatus } from "../../../utils/spenpdingDemandStatusConverter";
import useUserAuth from "../../../../auth/hook/useUserAuth";
import EmptyListIcon from "../../../../../components/atoms/EmptyListIcon/EmptyListIcon";
import Loader from "../../../../../components/atoms/Loader/Loader";
import { SpendingDemand } from "../../../types/spendingDemand.types";
import { useNavigate } from "react-router-dom";


const ToProcessStatus : ReadableStatus  = {
  status: "A traiter",
  color: {
    light: "#f4df8c8b",
    normal: "#f1c92b",
    dark: "#5d5535",
  },
  JWayIcon: "thunder",
}

export default function SpendingDemandListPresenter() {
  const {
    allSpendingDemandList: { data: spendingDemandListData, isLoading },
    selectSpendingDemand,
    unSelectSpendingDemand,
    isSpendingDemandeSelected, isSelectMode
  } = useSpendingDemand();

  const { userInfo } = useUserAuth();
  const pagination = spendingDemandListData?.pagination;


  const navigate = useNavigate();


  const handleClick = (spendingDemandId: string) => {
    navigate('/spending_demand/'+spendingDemandId)
  }

  const handleCheck = (spendingDemand: SpendingDemand) => {
    if (isSpendingDemandeSelected(spendingDemand.spending_demand_id)) {
      unSelectSpendingDemand(spendingDemand);
    } else {
      selectSpendingDemand(spendingDemand);
    }
  }
  

  

  const formated: ItemSpending[] | undefined =
    spendingDemandListData?.data?.map((spending) => {
      const isToProcess = userInfo?.user_id
        ? spending.manager_employee_id_waiting_for_validation.includes(
            userInfo?.user_id
          )
        : false;
      const statusObject = spendingDemandStatusRawToReadableStatus(
        spending.spending_demand_status,
        "for-user",
        isToProcess
      );

      return {
        id: spending.spending_demand_id,
        title: spending.spending_demand_desc,
        userFirstName: spending.spending_demand_user.username,
        userLastName: spending.spending_demand_user.lastname,
        createdDate: dateConverter(spending.spending_demand_date),
        grossAmmount: spending.spending_demand_amountTTC,
        status: statusObject.status,

        pinColor:
          spendingDemandStatusRawToReadableStatus(
            spending.spending_demand_status,
            "general"
          ).color.normal || undefined,
        withPin: isToProcess,
        colorStatus: isToProcess && spending.spending_demand_status === "WAITING_VALIDATION" ? {
          backgroundColor: ToProcessStatus.color.light,
          color: ToProcessStatus.color.dark,
          iconType: ToProcessStatus.JWayIcon
        } :{
          backgroundColor: statusObject.color.light,
          color: statusObject.color.dark,
          iconType: statusObject.JWayIcon
          
        },
        isChecked: isSpendingDemandeSelected(spending.spending_demand_id),
        onCheck: () => handleCheck(spending as any as SpendingDemand),
        onClick: () => handleClick(spending.spending_demand_id)
      };
    });

  if (isLoading) {
    return <Loader paddingTop="20%" centered />;
  }
  if (!formated) {
    return <EmptyListIcon message="Une erreur est survenue" />;
  }
  if (formated && formated.length === 0) {
    return <EmptyListIcon message="Aucune demande de dépense trouvée" />;
  }


  return (
    <>
      <SpendingDemandListView spendingList={formated || []} isCheckMode={true} />
      <Pagination
        centered
        currentPage={pagination?.current_page}
        linkBase="/spending_demand"
        lastPage={pagination?.total_pages}
      />
    </>
  );
}
