/*===================================================================
=     SPENDING DEMAND PAGE            =
====================================================================*/
/**
 * Spending 
 */
import React from 'react'
import './spendingDemandListPage.scss';
import SpendingDemandList from '../../../features/spendingDemand/components/SpendingDemandList';


const  SpendingDemandPage = () => {

  return (
    
    <div className='page'>
    <div className='page--title'>Demande de dépenses</div>
    <div style={{display: 'flex', justifyContent: 'space-between', gap: '10px'}}>


    <SpendingDemandList.Filter/>
  

    <div style={{width: "100%"}}>
    <SpendingDemandList.GroupedActionBtn />
    </div>
    </div>

      <SpendingDemandList.List/>
    </div>
  )
}

export default SpendingDemandPage
