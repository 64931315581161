import React, { FC } from "react";
import styles from "./Timeline.module.scss";

import start from "./assets/icon-start.svg";
import validIcon from "./assets/valid.svg";
import crossIcon from "./assets/cross.svg";
import warningIcon from "./assets/warning.svg";
import penIcon from "./assets/pen.svg";

export type IconType = "start" | "valid" | "cross" | "warning" | "pen";

export interface TimelineItem {
  iconType?: IconType;
  title?: string;
  description?: string;
  IconRender?: FC;
  color?: string;
  isDone?: boolean;
  subDescription?: string;
}
export interface TimelineProps {
  items?: TimelineItem[];
}

const getIconSrc = (icon: IconType | undefined) => {
  switch (icon) {
    case "start":
      return start;
    case "valid":
      return validIcon;
    case "cross":
      return crossIcon;
    case "warning":
      return warningIcon;
    case "pen":
        return penIcon;
    default:
      return "";
  }
};

function Timeline({ items }: TimelineProps) {
  if (!items) {
    return <></>;
  }

  const nbDone = items.filter((item) => item.isDone).length;
  const allToDo = items.length;
  const lastItemColorDone = items.filter((item) => item.isDone).pop()?.color;

  const lastItem = items[items.length - 1]
  const fullHeight = ((nbDone - 1) * 100) / allToDo;
  const dynamicStyle = {
    height: allToDo === nbDone ? "100%" : `calc(${fullHeight}% + 15px)`,
    backgroundColor: lastItemColorDone || undefined,
  };



  return (
    <div>
    <div className={styles["timeline"]}>
      <div
   
        className={[
          styles["line"],
          items.length <= 1 ? styles["line--transparent"] : "",
        ].join(" ")}
      >
        <div className={styles["line__full"]} style={dynamicStyle} />
      </div>
      <div className={styles["content"]}>
        {items.slice(0, -1).map((item, index) => (
        <div  key={index} className={styles["content__item"]}>
            <div style={{backgroundColor: item.isDone ? item.color : undefined}}className={styles["content__item__line"]}/>
            <div className={styles["content__item__pin"]}>
          <TimelineItem
            key={index}
            title={item.title}
            description={item.description}
            isFirst={index === 0}
            IconRender={
              item.iconType !== undefined
                ? () => <img src={getIconSrc(item.iconType)} />
                : undefined
            }
            color={item.color}
            isActive={item.color === undefined ? item.isDone : undefined}
            subDescription={item.subDescription}
          />
             </div >

          </div>

          


        ))}
      </div>
      
    </div>
    
    <div className={styles["last-point"]}>
        {lastItem && 
    <TimelineItem
     
            title={lastItem.title}
            description={lastItem.description}
            IconRender={
              lastItem.iconType !== undefined
                ? () => <img src={getIconSrc(lastItem.iconType)} />
                : undefined
            }
            color={lastItem.color}
            isActive={lastItem.color === undefined ? lastItem.isDone : undefined}
            subDescription={lastItem.subDescription}
          />}
    </div>
    </div>
  );
}

interface TimelineItemProps {
  IconRender?: FC;
  title?: string;
  description?: string;
  color?: string;
  isActive?: boolean;
  subDescription?: string;
  isFirst?: boolean;
}

function TimelineItem({
  title,
  description,
  IconRender,
  color,
  isActive,
  subDescription,
  isFirst
}: TimelineItemProps) {
  const dynamicStyle = {
    backgroundColor: isActive ? "#A6C185" : color || "#dedede",
  };
  return (
    <div className={styles["timeline-item"]}>
      <div
        style={dynamicStyle}
        className={[styles["timeline-item__dote"], isFirst? styles["timeline-item__dote--first"] : ''].join(" ")}
      >
        {IconRender && (
          <div className={styles["icon"]}>
            <IconRender />
          </div>
        )}
      </div>
      <div className={styles["timeline-item-content"]}>
        {title && (
          <h3 className={styles["timeline-item-content__title"]}>{title}</h3>
        )}
                {subDescription && (
          <p className={styles["timeline-item-content__sub-description"]}>
            {subDescription}
          </p>
        )}
        {description && (
          <p className={styles["timeline-item-content__description"]}>
            {description}
          </p>
        )}

      </div>
    </div>
  );
}

export default Timeline;
