import { IconType, JWayIcon } from "../../../assets/JWayIcons";
import styles from "./StatusBox.module.scss";

export interface StatusBoxProps {
  label?: string;
  size?: {
    width?: string;
    height?: string;
    font?: string;
    lineHeight?: string;
    minHeight?: string;
  };
  color?: {
    background?: string;
    text?: string;
  };
  children?: React.ReactNode;
  iconType?: IconType;
}

/**
 * Status Box Label
 * @param param0
 * @returns
 */
export default function StatusBox({
  children,
  label,
  color,
  size,
  iconType,
}: StatusBoxProps) {

  const getIconSize = (size: number, iconType: IconType) => {
    switch (iconType) {
      case "thunder":
        return size-4

      default:
        return size;
    }
  }

  return (
    <div
      style={{
        color: color?.text,
        backgroundColor: color?.background,
        fontSize: size?.font,
        width: size?.width,
        height: size?.height,
        lineHeight: size?.lineHeight,
        minHeight: size?.minHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: !iconType ?"center" : undefined,
      }}
      className={[styles["statusBox-container"]].join(" ")}
    >

      
      {iconType && (
        <div className={styles["statusBox-container__icon"]}>
          <JWayIcon type={iconType} size={getIconSize(14, iconType)} color={color?.text} />
        </div>
      )}
      
      <span className={styles["statusBox-container__label"]}>
        {label && label}
        {children}
      </span>
    </div>
  );
}
