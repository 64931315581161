import { useEffect, useState } from "react";
import AproveDisaproveActionView from "./AproveDisaproveActionView/AproveDisaproveActionBtnView";
import { useSingleSpendingDemand } from "../../../hooks";
import useUserAuth from "../../../../auth/hook/useUserAuth";

const AproveDisaproveActionBtnPresenter = () => {
  // Implement the component logic here
  const { userInfo } = useUserAuth();
  const {
    sendAskForUpdateSpendingDemand,
    sendValidateSpendingDemand,
    sendRefuseSpendingDemand,
    singleSpendingDemandData,
  } = useSingleSpendingDemand();

  const [remarks, setRemarks] = useState("");

  useEffect(() => {
    if (
      sendAskForUpdateSpendingDemand.isSucess ||
      sendValidateSpendingDemand.isSucess ||
      sendRefuseSpendingDemand.isSucess
    ) {
      setRemarks("");
    }
  }, [
    sendAskForUpdateSpendingDemand.isSucess,
    sendValidateSpendingDemand.isSucess,
    sendRefuseSpendingDemand.isSucess,
  ]);
  if (!singleSpendingDemandData) {
    return <></>;
  }
  const isToProcess = userInfo?.user_id
    ? singleSpendingDemandData.manager_employee_id_waiting_for_validation.includes(
        userInfo?.user_id
      )
    : false;

  if (
    sendAskForUpdateSpendingDemand.isPending ||
    sendValidateSpendingDemand.isPending ||
    sendRefuseSpendingDemand.isPending
  ) {
    return <p>Envoi en cours... </p>;
  }
  if (isToProcess) {
    return (
      <div>
        <AproveDisaproveActionView
          approveBtn={{
            onClick: () => {
              sendValidateSpendingDemand.run({ message: remarks });
            },
            disable: sendValidateSpendingDemand.isPending,
          }}
          refuseBtn={{
            onClick: () => {
              sendRefuseSpendingDemand.run({ message: remarks });
            },
            disable: sendRefuseSpendingDemand.isPending,
          }}
          askForUpdateBtn={{
            onClick: () => {
              sendAskForUpdateSpendingDemand.run({ message: remarks });
            },
            disable: sendAskForUpdateSpendingDemand.isPending,
          }}
          setRemaks={setRemarks}
          remaksValue={remarks}
        />
      </div>
    );
  }
  return <></>;
};

export default AproveDisaproveActionBtnPresenter;
