import React from "react";
import styles from "./EmptyListIcon.module.scss";

const DrawerIcon = ({color, size}:{color?: string, size?: number}) => (
  <svg width={size} viewBox="0 0 81 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M80.4431 26.4116L60.1931 0.957095C59.9564 0.658971 59.656 0.418173 59.3143 0.252508C58.9725 0.0868424 58.5981 0.00054647 58.2188 2.75666e-06H22.7812C22.4017 -0.000560652 22.0269 0.0852456 21.685 0.250993C21.3431 0.41674 21.0429 0.658133 20.8069 0.957095L0.556875 26.4116C0.197403 26.8622 0.00102335 27.4223 0 28L0 50.9091C0 52.2593 0.533369 53.5542 1.48277 54.5089C2.43217 55.4636 3.71984 56 5.0625 56H75.9375C77.2802 56 78.5678 55.4636 79.5172 54.5089C80.4666 53.5542 81 52.2593 81 50.9091V28C81.0002 27.4221 80.8037 26.8616 80.4431 26.4116ZM75.9337 29.3167C75.9358 29.9947 75.3867 30.5455 74.7087 30.5455H61.5456C59.4161 30.5455 57.3745 31.3945 55.8729 32.9044L50.4396 38.3683C48.938 39.8783 46.8964 40.7273 44.7669 40.7273H36.2331C34.1036 40.7273 32.062 39.8783 30.5604 38.3683L25.1271 32.9045C23.6255 31.3945 21.5839 30.5455 19.4544 30.5455H6.28877C5.61152 30.5455 5.0625 29.9964 5.0625 29.3192V29.3192C5.0625 29.0419 5.15649 28.7728 5.32913 28.5558L21.5941 8.11041C23.1119 6.20257 25.4168 5.09091 27.8547 5.09091H53.1402C55.5782 5.09091 57.883 6.20257 59.4008 8.11041L75.6674 28.5577C75.839 28.7735 75.9329 29.0409 75.9337 29.3167V29.3167Z" fill={color}/>
</svg>

)

export interface EmptyListIconProps {
  message?: string;
  marginTop?: string;
  color?: string;
}
export default function EmptyListIcon({ message, marginTop = '20%', color= '#5555557a'}: EmptyListIconProps) {
  const styleDynamic= {
    marginTop: marginTop,
    color: color
  }
  return (
    <div  style={styleDynamic} className={styles["empty-list-icon"]}>
      <div className={styles["empty-list-icon__icon"]}><DrawerIcon color={color} size={80}/></div>
      <p className={styles["empty-list-icon__text"]}>{message}</p>
    </div>
  );
}
