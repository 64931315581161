import React, { useState } from 'react'
import { useSpendingDemand } from '../../../hooks'

import styles from './SelectGroupedAction.module.scss'
import Button from '../../../../../components/atoms/Button/Button'
import SelectInput from '../../../../../components/atoms/SelectInput/SelectInput'
import { Action } from '../../../hooks/core/useSpendingDemandCore'
export default function SelectGroupedAction() {
    const [actionChoosen, setActionChoosen] = useState<Action>('VALIDATE')
    const { spendingDemandSelected,groupedActions, isLoadingActionGrouped } = useSpendingDemand()
    const handleClick = () => {
        console.log('groupedActions', groupedActions)
        groupedActions(actionChoosen)
    }
    if (spendingDemandSelected?.length === 0) return <></>


  return (

    <div className={styles['select-grouped-action-container']}>
        <div className={styles['select-grouped-action-container__title']}>Actions groupées</div>
        <SelectInput
        label='Action'
        options={[{
            label: 'Valider',
            value: 'VALIDATE'
        },/* {
            label: 'Refuser',
            value: 'REFUSE'
        }*/]} value={actionChoosen} handleChange={(value) => {setActionChoosen(value)}}  />
        <Button onClick={handleClick} label="Appliquer" isLoading = {isLoadingActionGrouped}/>
    </div>
  )
}
