
export interface IconProps{
    color?: string
    size?: number
}

export const PlusIcon = ({color = '#000', size = 24}: IconProps) => {
    return (<svg width={size} height={size} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.9286 8.57143H8.57143V13.9286C8.57143 14.2127 8.45855 14.4853 8.25761 14.6862C8.05668 14.8871 7.78416 15 7.5 15C7.21584 15 6.94332 14.8871 6.74239 14.6862C6.54145 14.4853 6.42857 14.2127 6.42857 13.9286V8.57143H1.07143C0.787268 8.57143 0.514746 8.45855 0.313814 8.25762C0.112883 8.05668 0 7.78416 0 7.5C0 7.21584 0.112883 6.94332 0.313814 6.74239C0.514746 6.54145 0.787268 6.42857 1.07143 6.42857H6.42857V1.07143C6.42857 0.787268 6.54145 0.514746 6.74239 0.313814C6.94332 0.112882 7.21584 0 7.5 0C7.78416 0 8.05668 0.112882 8.25761 0.313814C8.45855 0.514746 8.57143 0.787268 8.57143 1.07143V6.42857H13.9286C14.2127 6.42857 14.4853 6.54145 14.6862 6.74239C14.8871 6.94332 15 7.21584 15 7.5C15 7.78416 14.8871 8.05668 14.6862 8.25762C14.4853 8.45855 14.2127 8.57143 13.9286 8.57143Z" fill={color}/>
        </svg>
        )
}

export const getIcon = (iconName: string, props?: IconProps) => {
    switch(iconName) {
        case 'plus':
            return <PlusIcon {...props}/>
        default:
            return  <></>
    }
}