import { UserFilter } from "../types/user.types";

export interface Params {
    filter?: UserFilter
    withQuestionMark?: boolean
}

export const getUserFilter = ({filter, withQuestionMark}: Params) : string => {


    let filterString = "";

    if (withQuestionMark){
        filterString += "?";
    }
    if(filter?.user_is_active){
        filterString += "user_is_active==true";
    }
    return filterString;
} 