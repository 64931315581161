import React from 'react'
import styles from './Button.module.scss'
import { Link } from 'react-router-dom';
import arrowBlack from './assets/arrow-icon-black.svg';
import { getIcon } from './assets/icon';
import Loader from '../Loader/Loader';
export interface ButtonProps {

  label?: string;
  onClick?: () => void;
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'tercery' |'red' | 'green' | 'black' | 'gray' | 'dark-parimary' | 'outlined';
  bgColorHex?: string;
  fontColorHex?: string;
  hoverColorHex?: string;
  IconComponent?: React.FC;
  IconComponentRight?: React.FC;
  IconComponentLeft?: React.FC;
  linkTo?: string;
  isSubmit?: boolean;
  maxWidth?: string;
  iconArrow?: boolean;
  height?: string;
  isLoading ?: boolean;
  children?: React.ReactNode;
  iconRightType?: 'plus';
  type?: 'button' | 'submit' | 'reset';
  borderRaduis?: string;
  fontSize?: number | string;
  borderColor?: string;

}

export default function Button({label,isLoading, borderColor, fontSize, borderRaduis, onClick, disabled, variant = 'primary', bgColorHex, fontColorHex, hoverColorHex, IconComponent, linkTo,  IconComponentRight, maxWidth, iconArrow,   IconComponentLeft, height, children, iconRightType, type }: ButtonProps) {

  const btnStyle = {
    color: fontColorHex || 'none',
    backgroundColor: bgColorHex || 'none',
    maxWidth: maxWidth || 'none',
    height: height || 'none',
    borderRadius: borderRaduis ,
    border: borderColor ? `1px solid ${borderColor}` : undefined,
  }

  const getIconColor = () => {
    switch (variant) {
      case 'primary':
        return '#fff'
      case 'secondary':
        return '#000'
      case 'tercery':
        return '#000'
      case 'red':
        return '#fff'
      case 'green':
        return '#fff'
      case 'black':
        return '#fff'
      case 'gray':
        return '#000'
      case 'dark-parimary':
        return '#1e1e1e'
      default:
        return '#fff'
    }
  }
  const disabledFormated = disabled || isLoading
  const disabledStyle = disabledFormated ? "disabled" : "not-disabled"
  const Content = () => (
    <div style={btnStyle} className={[styles['btn-content'],styles['btn-content--'+variant], styles['btn-content--'+variant+'--'+disabledStyle], disabledFormated && styles['btn-content--disabled']].join(" ")}> 
      {!isLoading ? 
      <>
      {IconComponentLeft && <div className={[styles['btn-content__icon-component-left'],!label && styles['btn-content__icon-component-left--no-label'] ].join(" ")}><IconComponentLeft /></div>}
      {IconComponent && <IconComponent />}
      {children}
    
      {label && !children && <span style={{fontSize: fontSize}} className={[styles['btn-content__label']].join(" ")}>{label}</span>}
      { IconComponentRight && <div className={[styles['btn-content__icon-component-right'], !label && styles['btn-content__icon-component-right--no-label'] ].join(" ")}><IconComponentRight /></div>}
      {iconArrow && <img className={styles['btn-content__arrow-icon']} src={arrowBlack} alt="arrow" />}
      {iconRightType &&<div className={[styles['btn-content__icon-type'], styles['btn-content__icon-type--right']].join(" ")}> {getIcon(iconRightType, {color: getIconColor(), size: 12})}</div>}
      </>:
      <div className={styles['loading']}><Loader size={20}/></div>
      }

    </div>
  )

  if(linkTo && !disabledFormated ) {
    return (
      <Link className={styles['link']} to={linkTo}>
   
          <Content />

      </Link>
    )
  }



  return (
    <button type={type} className={[styles['button'], disabledFormated && styles['button--disabled'] ].join(" ")} onClick={ onClick ? (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (onClick && !disabledFormated ) onClick()
    } : undefined}>
      <Content />
    </button>
  )
}
