import React from "react";
import SpendingDemandListView, {
  ItemSpending,
} from "../../../../spendingDemand/components/SpendingDemandList/SpendingDemandList /SpendingDemandListView/SpendingDemandListView";
import { useSingleTeam } from "../../../hooks";
import { dateConverter } from "../../../../../utils/dateConverter";


import { spendingDemandStatusRawToReadableStatus } from "../../../../spendingDemand/utils/spenpdingDemandStatusConverter";

export default function SpendingDemandListPresenter() {
  const { spendingDemandList } = useSingleTeam();
  //const [page, setPage] = React.useState(1);

  const { spendingDemandListData } = spendingDemandList;
  //const pagination = spendingDemandListData?.pagination;
  const formated: ItemSpending[] | undefined =
    spendingDemandListData?.data?.map((spending) => {
      const statusObject = spendingDemandStatusRawToReadableStatus(
        spending.spending_demand_status,
        "for-user"
      );
      

      return {
        id: spending.spending_demand_id,
        title: spending.spending_demand_desc,
        userFirstName: spending.spending_demand_user.username,
        userLastName: spending.spending_demand_user.lastname,
        createdDate: dateConverter(spending.spending_demand_date),
        grossAmmount: spending.spending_demand_amountTTC,
        status: statusObject.status,
        pinColor:
          spendingDemandStatusRawToReadableStatus(
            spending.spending_demand_status,
            "for-user"
          ).color.normal || undefined,
        withPin: spending.is_to_process,
        colorStatus: {
          backgroundColor: statusObject.color.light,
          color: statusObject.color.dark,
        },
      };
    });
  return (
    <>
      <SpendingDemandListView
        spendingList={formated || []}
        emptyMessage="Aucune dépense associée à cette équipe"
      />
    </>
  );
}
