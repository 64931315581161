import React from 'react'
import styles from './CheckCase.module.scss'
import { JWayIcon } from '../../../assets/JWayIcons';

export interface CheckCaseProps {
    isChecked?: boolean;
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined

}

export default function CheckCase({isChecked, onClick}: CheckCaseProps) {

  return (
    <div onClick={onClick} className={[styles['check-case-container'],isChecked ? styles['check-case-container--checked'] : ""].join(" ")}>
      <div className={[styles['check-case-container__icon'],isChecked ? styles['check-case-container__icon--checked'] : ""].join(" ")}>
        <JWayIcon type={"check-valid"} size={14} color='white'/>
      </div>
    </div>
  )
}


