import { IconType } from "../../../assets/JWayIcons";

export interface ReadableStatus {
    status: string;
    color: {
      light: string;
      normal: string;
      dark: string;
    };
    JWayIcon?: IconType
  }
export const spendingDemandStatusRawToReadableStatus = (
    statusRaw: string,mode: "general" | "for-user",isToProcessByCurrentManager?: boolean, 
  ) : ReadableStatus=> {
    switch (statusRaw) {
      case "REFUSED":
        return {
          status: "Refusée",
          color: {
            light: "#F0C5C58b",
            normal: "#F0C5C5",
            dark: "#6E4444",
          },
          JWayIcon: "prohibited"

        };
      case "VALIDATE":
        return {
          status: "Validée",
          color: {
            light: "#D0F0C58b",
            normal: "#D0F0C5",
            dark: "#4F6E44",
          },
          JWayIcon: "check-valid"
        };
      case "WAITING_UPDATE":
        return{
          status: "En attente de correction",
          color: {
            light: "#f4df8c8b",
            normal: "#f1c92b",
            dark: "#5d5535",
          },

        }
      case "WAITING_VALIDATION":
        return  {
          status: (mode === 'general' ? 'En attente de traitement / À traiter' : isToProcessByCurrentManager ?"À traiter" : "Traitement "),
          color: {
            light: "#ecdbc98b",
            normal: "#D27513",
            dark: "#83490c"
          }

      };

      case "PAYED":
        return  {
          status: ("Payée"),
          color: {
            light: "#c8c8c88b",
            normal: "#6b6b6b",
            dark: "#343434",
          },
          JWayIcon: "coin"
      };
      default:
        return {
          status: statusRaw,
          color: {
            light: "#EDF0F18b",
            normal: "#EDF0F1",
            dark: "#000000",
          },
        };
    }
  };