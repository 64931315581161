export interface IconProps {
  color?: string;
  size?: number;
}

export const BinIcon = ({ color = "#313131" }: IconProps) => {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4489 1H10.9489L9.94885 0H4.94885L3.94885 1H0.448853V3H14.4489M1.44885 16C1.44885 16.5304 1.65957 17.0391 2.03464 17.4142C2.40971 17.7893 2.91842 18 3.44885 18H11.4489C11.9793 18 12.488 17.7893 12.8631 17.4142C13.2381 17.0391 13.4489 16.5304 13.4489 16V4H1.44885V16Z"
        fill={color}
      />
    </svg>
  );
};

export const PenIcon = ({ color = "#313131" }: IconProps) => {
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 19V14.5139L16.1333 0.606945C16.3778 0.413426 16.6479 0.263889 16.9437 0.158333C17.2394 0.0527779 17.5499 0 17.875 0C18.2001 0 18.5158 0.0527779 18.8222 0.158333C19.1286 0.263889 19.3934 0.422222 19.6167 0.633333L21.2972 2.11111C21.5417 2.30463 21.7201 2.53333 21.8325 2.79722C21.945 3.06111 22.0008 3.325 22 3.58889C22 3.87037 21.9442 4.13883 21.8325 4.39428C21.7209 4.64972 21.5425 4.88265 21.2972 5.09306L5.19444 19H0ZM17.8444 5.06667L19.5555 3.58889L17.8444 2.11111L16.1333 3.58889L17.8444 5.06667Z"
        fill={color}
      />
    </svg>
  );
};
