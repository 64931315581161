import React from 'react'
import { useSpendingDemand } from '../../../hooks'

export default function SelectActiveBtn() {
    const {setSelectMode, isSelectMode} = useSpendingDemand()
    const handleClick = () => {
        setSelectMode(!isSelectMode)
    }
  return (
    <button onClick={handleClick}>{!isSelectMode ? "sélectionner" : "Fin" }</button>
  )
}
