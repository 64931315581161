import React from 'react'

import styles from "./textBox.module.scss";
export interface ContentItemsProps{
    title?: string;
    text?: string;
    unit?: string;
    ItemRender ?: React.FC;
}

export interface TextBoxProps {
    content: ContentItemsProps[];
    color?: 'primary' | 'secondary';
    colorStyle?:{backgroundColor?: string; color?: string};
    
}

export default function TextBox({content, color = 'secondary', colorStyle} : TextBoxProps) {

  return (
    <div style={{...colorStyle }}className={[styles['text-box'],styles['text-box--'+color]].join(" ")}>
        {content.map((item, index) => (
            <div key={index}>
            <div className={styles['text-container']}>
                <h2 className={styles['text-container__title']}>{item.title}</h2>
                { (item.text || item.unit) &&
                    <p className={styles['text-container__text']}>{item.text} {item.unit}</p>
                }
                { item.ItemRender &&
                    <div className={styles['text-container__render']}>
                    <item.ItemRender />
                    </div>
                }

            </div>
            </div>
        ))}
    </div>
  )
}

