/*===================================================================
=       GENERAL INFO VIEW                     =
====================================================================*/
import FilesList, {
  FileItem,
} from "../../../../../../components/molecules/FilesList/FilesList";
import TextBox from "../../../../../../components/molecules/TextBox/TextBox";
import styles from "./GeneralInfoView.module.scss";
import HistoricStatusPresenter from "../../HistoricStatus/HistoricStatusPresenter";

export interface User {
  firstname?: string;
  lastname?: string;
}

export interface GeneralInfoViewProps {
  userName?: string;
  userLastname?: string;
  statut?: string;
  colorStatus?: {backgroundColor?: string; color?: string};
  paymentDate?: string;
  amount?: string;
  description?: string;
  updateAtDate?: string;
  projectName?: string;
  remak?: string;
  filesList?: FileItem[] | null;
  managerWaitingValidation?: User[];
}
export default function GeneralInfoView({
  userLastname,
  userName,
  statut,
  colorStatus,
  paymentDate,
  description,
  amount,
  projectName,
  updateAtDate,
  remak,
  filesList,
  managerWaitingValidation,
}: GeneralInfoViewProps) {
  const managerListWaitingValidationString =
    managerWaitingValidation
      ?.map((manager) => manager?.firstname + " " + manager?.lastname)
      .join(", ") || "";

  const statusContent = [
    {
      title: "Statut",
      text: statut,
    },
  ];

  if (
    managerWaitingValidation &&
    managerWaitingValidation.length > 0 &&
    statut === "En attente de traitement"
  ) {
    statusContent.push({
      title: "En attente de la validation de",
      text: managerListWaitingValidationString,
    });
  }

  return (
    <div className={styles["general-info"]}>
      <div className={[styles["row"]].join(" ")}>
        <div className={[styles["column"], styles["column--left"]].join(" ")}>
          <div className={[styles["item"]].join(" ")}>
            
            <TextBox
              color="primary"
              content={[
                {
                  title: "Montant Previsionel TTC",
                  text: amount,
                },
              ]}
            />
          </div>
        </div>
        <div className={[styles["column"], styles["column--right"]].join(" ")}>
          <div className={[styles["item"]].join(" ")}>
            <TextBox           colorStyle={colorStatus} content={statusContent} />
          </div>
        </div>
      </div>
      <div className={[styles["row"], styles["row--main"]].join(" ")}>
        <div className={[styles["column"], styles["column--left"]].join(" ")}>
          <div className={[styles["item"]].join(" ")}>
            <TextBox
              content={[
                {
                  title: "Date de paiement",
                  text: paymentDate,
                },
                {
                  title: "Date de modification",
                  text: updateAtDate,
                },
              ]}
            />
          </div>
          <div className={[styles["item"]].join(" ")}>
            <TextBox
              content={[
                {
                  title: "Description",
                  text: description,
                },
              ]}
            />
          </div>
          <div className={[styles["item"]].join(" ")}>
            <TextBox
              content={[
                {
                  title: "Employé(e)",
                  text: userName + " " + userLastname,
                },
              ]}
            />
          </div>
          {projectName && projectName !== "" && (
            <div className={[styles["item"]].join(" ")}>
              <TextBox
      
                content={[
                  {
                    title: "Projet",
                    text: projectName,
                  },
                ]}
              />
            </div>
          )}
        </div>
        <div
          className={[
            styles["column--historic-status"],
            styles["column"],
            styles["column--right"],
          ].join(" ")}
        >
          <div className={[styles["item"]].join(" ")}>
            <TextBox
              content={[
                {
                  title: "Historique de traitement",
                  ItemRender: () => <HistoricStatusPresenter />,
                },
              ]}
            />
          </div>
        </div>
      </div>

      <div className={[styles["row"], styles["row--description"]].join(" ")}>
        <div
          className={[styles["column"], styles["column--letf"]].join(" ")}
        ></div>
      </div>
      <div className={[styles["row"], styles["row--employee"]].join(" ")}>
        <div
          className={[styles["column"], styles["column--letf"]].join(" ")}
        ></div>
      </div>



      {filesList && filesList.length > 0 && (
        <div className={[styles["row"], styles["row--files"]].join(" ")}>
          <div className={[styles["column"], styles["column--letf"]].join(" ")}>
            <FilesList filesList={filesList} label="Justificatifs" />
          </div>
        </div>
      )}
    </div>
  );
}
