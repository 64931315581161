import React from 'react'
import HistoricStatusView from './HistoricStatusView/HistoricStatusView'
import { TimelineItem } from '../../../../../components/molecules/Timeline/Timeline'
import { useSingleSpendingDemand} from '../../../hooks'

import { SpendingDemandHistoricStatus } from '../../../types/spendingDemandHistoricStatut.types'
import { dateConverter } from '../../../../../utils/dateConverter'



export default function HistoricStatusPresenter() {




const {historicStatus, singleSpendingDemandData: singleSpending, isLoading,managerWaitingValidation } =  useSingleSpendingDemand()
const userName = singleSpending?.spending_demand_user?.username
const userLastName = singleSpending?.spending_demand_user?.lastname

const listManagerWaitingValisation = managerWaitingValidation.data
if(historicStatus.isPending || isLoading){
  return <span>Chargement...</span>
}

if(historicStatus.isError){
  return <span>Erreur de chargement</span>
}

if(!historicStatus.data){
  return <span>Pas de données</span>
}

const listPoint : TimelineItem[]= [

]

const getDateString = (status:  SpendingDemandHistoricStatus)=> {
  return dateConverter(status.spending_demand_sh_created_at)
}

if((singleSpending?.spending_demand_status === 'WAITING_VALIDATION')

  || (singleSpending?.spending_demand_status === 'WAITING_VALIDATION_ADMIN')
  || (singleSpending?.spending_demand_status === 'WAITING_VALIDATION_MANAGER')
){
  listPoint.push({
    title: 'Validation en attente',
    description: "En attente d'une validation  de "+listManagerWaitingValisation.map((manager) => manager?.username+' '+manager?.lastname).join(', '),
    iconType: 'valid',
    isDone: false,
  })
}

if(historicStatus.data?.data && historicStatus.data.data.length  > 0){

  const dataSorted = historicStatus.data.data.sort((a: SpendingDemandHistoricStatus, b: SpendingDemandHistoricStatus) => {
    return  new Date(b.spending_demand_sh_created_at).getTime() - new Date(a.spending_demand_sh_created_at).getTime()
  })


  dataSorted.forEach((status: SpendingDemandHistoricStatus) => {

    if(status?.spending_demand_sh_status === 'REFUSED'){
      listPoint.push({
        title: 'Refusée',
        description: 'Par '+status?.spending_demand_sh_user?.username+' '+status?.spending_demand_sh_user?.lastname+  ' Le '+getDateString(status)+'',
        iconType: 'cross',
        color: '#CE8585',
        isDone: true,
        subDescription:( (status?.spending_demand_sh_remak && status?.spending_demand_sh_remak !== "") ?''+status?.spending_demand_sh_remak : undefined)
      })
    }

    if(status?.spending_demand_sh_status === 'WAITING_UPDATE'){
      listPoint.push({
        title: 'Demande de correction',
        description:'Par '+status?.spending_demand_sh_user?.username+' '+status?.spending_demand_sh_user?.lastname+  ' Le '+getDateString(status)+'',
        iconType: 'warning',
        color: '#e8ad61',
        isDone: true,
        subDescription:( (status?.spending_demand_sh_remak && status?.spending_demand_sh_remak !== "") ?''+status?.spending_demand_sh_remak : undefined)
      })
    }
    if(status?.spending_demand_sh_status === 'WAITING_VALIDATION'){
      listPoint.push({
        title: 'Modification apportée',
        description:'Par '+status?.spending_demand_sh_user?.username+' '+status?.spending_demand_sh_user?.lastname+  ' Le '+getDateString(status)+'',
        iconType: 'pen',
        color: '#e6880d',
        isDone: true,
       // subDescription:( (status?.spending_demand_sh_remak && status?.spending_demand_sh_remak !== "") ?''+status?.spending_demand_sh_remak : undefined)
      })
    }

      if (status?.spending_demand_sh_status === 'WAITING_VALIDATION' && status.spending_demand_sh_user?.user_id !== singleSpending?.spending_demand_user?.user_id) {
        listPoint.push({
          title: 'Validée',
          description:'Par '+status?.spending_demand_sh_user?.username+' '+status?.spending_demand_sh_user?.lastname+  ' Le '+getDateString(status)+'',
          iconType: 'valid',
          color: '#A6C185',
          isDone: true,
          subDescription:( (status?.spending_demand_sh_remak && status?.spending_demand_sh_remak !== "") ?''+status?.spending_demand_sh_remak : undefined)
        })

      }

      

      if (status?.spending_demand_sh_status === 'VALIDATE') {
        listPoint.push({
          title: 'Validée',
          description:'Par '+status?.spending_demand_sh_user?.username+' '+status?.spending_demand_sh_user?.lastname+  ' Le '+getDateString(status)+'',
          iconType: 'valid',
          color: '#A6C185',
          subDescription:( (status?.spending_demand_sh_remak && status?.spending_demand_sh_remak !== "") ?''+status?.spending_demand_sh_remak : undefined),
          isDone: true
        })

      }
    
      

  })


}



listPoint.push( {
  title: 'Créée',
  description: 'Par '+userName+' '+userLastName,
  iconType: 'start',
  color: '#A6C185',
  isDone: true
})

  return (
    <HistoricStatusView  items={listPoint}/>
  )
}