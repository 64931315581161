import styles from "./Table.module.scss";
import { BinIcon, PenIcon } from "./assets/icons";

import EmptyListIcon from "./EmptyListIcon/EmptyListIcon";
import { Check } from "@mui/icons-material";
import CheckCase from "../../atoms/CheckCase/CheckCase";

export interface TableRow {
  data: (string | number | undefined | (() => JSX.Element))[];
  id: string;
  onDelete?: () => void;
  onEdit?: () => void;
  onRowClick?: () => void;
  onCheck?: () => void;
  isChecked?: boolean;
  withNotif ?: boolean;

}
export type BodyRow = TableRow & {
  onRowClick?: () => void;
  onDeleteRow?: () => void;
  onEditRow?: () => void;
  variant?: Variant;
  withCheckCase?: boolean;
};

export type Variant = "default" | "dark";

export interface TableProps {
  headerTitle: string[];
  bodyData?: TableRow[];
  onRowClick?: (row: TableRow) => void;
  onDeleteRow?: (row: TableRow) => void;
  onEditRow?: (row: TableRow) => void;
  emptyMessage?: string;
  isLoading?: boolean;
  variant?: Variant;
  styleBody?: React.CSSProperties;
  minWidth?: string;
  withCheckCase?: boolean;
}
export default function Table({
  headerTitle,
  bodyData,
  onRowClick,
  onDeleteRow,
  onEditRow,
  isLoading,
  emptyMessage,
  variant = "default",
  styleBody,
  minWidth,
  withCheckCase,
}: TableProps) {
  if (!bodyData || bodyData.length === 0) {
    return (
      <div>
        <EmptyListIcon
          message={emptyMessage || "No Data"}
          color={variant === "dark" ? "#fff" : undefined}
        />
      </div>
    );
  }

  return (
    <table
      className={[styles["table"], styles["table--" + variant]].join(" ")}
      style={{ minWidth }}
    >
      <thead
        className={[
          styles["table-header"],
          styles["table-header--" + variant],
        ].join(" ")}
      >
        <tr
          className={[
            styles["table-header-row"],
            styles["table-header-row--" + variant],
          ].join(" ")}
        >
          {withCheckCase && (
            <th
              className={[
                styles["table-header-row__cell"],
                styles["table-header-row__cell--" + variant],
                styles["table-header-row__cell--check-case"],
              ].join(" ")}
            ></th>
          )}
          {headerTitle.map((title, index) => (
            <th
              className={[
                styles["table-header-row__cell"],
                styles["table-header-row__cell--" + variant],
              ].join(" ")}
              key={index}
            >
              {title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody
        style={styleBody}
        className={[
          styles["table-body"],
          styles["table-body--" + variant],
        ].join(" ")}
      >
        {bodyData?.map((row, index) => {
          const onDelete =
            row?.onDelete || onDeleteRow
              ? () => {
                  (row?.onDelete && row.onDelete()) ||
                    (onDeleteRow && onDeleteRow(row));
                }
              : undefined;
          const onEdit =
            row?.onEdit || onEditRow
              ? () => {
                  (row?.onEdit && row.onEdit()) ||
                    (onEditRow && onEditRow(row));
                }
              : undefined;
          return (
            <Row
              key={index}
              {...row}
              onRowClick={() => {
                row.onRowClick
                  ? row.onRowClick()
                  : onRowClick && onRowClick(row);
              }}
              onDeleteRow={onDelete}
              onEditRow={onEdit}
              variant={variant}
              withCheckCase={withCheckCase }
              isChecked={row.isChecked}
              onCheck={row.onCheck}
            />
          );
        })}
      </tbody>
    </table>
  );
}

export const Row = ({
  data,
  id,
  onRowClick,
  onDeleteRow,
  onEditRow,
  variant,
  withCheckCase,
  onCheck, 
  isChecked
}: BodyRow) => {
  return (
  
     
    <tr
      className={[
        styles["table-body-row"],
        styles["clickable"],
        styles["table-body-row--" + variant],
        styles["clickable--" + variant],
      ].join(" ")}
      onClick={(e) => {
        e.stopPropagation();
        onRowClick && onRowClick();
      }}
    >
       {/*<div className={[styles["table-body-row__pin-row"]].join(" ")} />*/}

      {withCheckCase && 
      <td          className={[
        styles["table-body-row__cell"],
        styles["table-body-row__cell--" + variant],
        styles["table-body-row__cell--check-case"],
      ].join(" ")}>
        <CheckCase isChecked={isChecked} onClick={(e) =>{e.stopPropagation(); onCheck && onCheck()}} />
      </td>
      }

      {data.map((cell, index) => {
        const Cellule = cell;

        return (
         
      
          <td
            className={[
              styles["table-body-row__cell"],
              styles["table-body-row__cell--" + variant],
            ].join(" ")}
            key={index}
          >
            {typeof Cellule === "function" ? (
              <div style={{ zIndex: 10 }}>
                <Cellule />
              </div>
            ) : (
              Cellule
            )}
          </td>
        );
      })}
      
   

      {(onDeleteRow || onEditRow) && (
        <td
          className={[
            styles["table-body-row__cell"],
            styles["table-body-row__cell--btn"],
            styles["table-body-row__cell--" + variant],
          ].join(" ")}
        >
          <ButtonAction
            onDeleteRow={onDeleteRow}
            onEditRow={onEditRow}
            color={variant === "dark" ? "white" : ""}
            variant={variant}
          />
        </td>
      )}
    
      
   
    </tr>

  );
};

export const ButtonAction = ({
  onDeleteRow,
  onEditRow,
  color,
  variant = "default",
}: {
  onDeleteRow?: () => void;
  onEditRow?: () => void;
  variant?: Variant;
  color?: string;
}) => {
  return (
    <div className={styles["button-action-container"]}>
      {onEditRow && (
        <button
          className={[
            styles["button-action-container__button"],
            styles["button-action-container__button--edit"],
            styles["button-action-container__button--" + variant],
          ].join(" ")}
          onClick={onEditRow}
        >
          <PenIcon color={color || undefined} />
        </button>
      )}
      {onDeleteRow && (
        <button
          className={[
            styles["button-action-container__button"],
            styles["button-action-container__button--delete"],
            styles["button-action-container__button--" + variant],
          ].join(" ")}
          onClick={onDeleteRow}
        >
          <BinIcon color={color || undefined} />
        </button>
      )}
    </div>
  );
};
