/*===================================================================
=     ErrorAxiosNetworkHandler             =
====================================================================*/
/**
 * Error Handler
 */

import { AxiosError } from "axios";

import { infoModalConfigPreset } from "../utils/infoModalConfigPreset";
import StatusCodes from "http-status-codes";
import { useDispatch } from "react-redux";
import { logoutUserAction } from "../features/auth/redux/userAuth.actions";
import useInfoModalV2 from "../components/organismes/InfoModalV2/context/infoModalV2Context";

/**
 * List of Intern Error
 */
export const internalErrorCodes = {
  PASSWORD_EMAIL_NOT_PROVIDED: 1000,
  SESSION_ENDED: 1001,
  FORM_NOT_VALID: 1002,
  INTERNAL_ERROR: 1003,
};

//EXTERNAL ERROR
const LIST_CODE_ERROR_MESSAGE: { [key: string]: string } = {
  ["SD01"]:
    "Désolé, aucune équipe comptable n'a été trouvée pour valider cette demande de dépense. \nveuillez contacter votre administrateur.",
};
// EMUN SCREEN Context
export type ScreenLocationType = "Login";

/**
 * Error Axios error handler
 * Manage error
 * interpret the error
 * @param error
 * @returns
 */
export default function useErrorHandler() {
  const { displayInfoModal } = useInfoModalV2();

  const dispatch = useDispatch();

  /**
   * Handle Intern Error
   * This function handle intern error
   * • Manage the error
   * • Dysplay the error to the user
   * @param errorMessage message to display to the user
   * @param errorCode error code
   * @returns
   */
  const handleInternError = (
    errorMessage: string | null,
    errorCode: number | null
  ) => {
    if (errorMessage) {
      console.log(errorMessage);
    }
    //PASSWORD EMAIL NOT PROVIDED
    if (errorCode === internalErrorCodes.PASSWORD_EMAIL_NOT_PROVIDED) {
      displayInfoModal(infoModalConfigPreset.PASSWORD_OR_ID_NOT_PROVIDED);
      return;
    }

    //SESSION ENDED
    if (errorCode === internalErrorCodes.SESSION_ENDED) {
      displayInfoModal(infoModalConfigPreset.SESSION_ENDED);
      return;
    }

    //FORM NOT VALID
    if (errorCode === internalErrorCodes.FORM_NOT_VALID) {
      displayInfoModal({
        ...infoModalConfigPreset.FORM_NOT_VALID,
        message:
          errorMessage ||
          "Veuillez verifier que les champs obligatoires aient bien une valeur valide svp",
      });
      return;
    }

    if (errorCode === internalErrorCodes.INTERNAL_ERROR) {
      displayInfoModal({
        ...infoModalConfigPreset.INTERN_ERROR,
        title: "Oupps",
        message:
          errorMessage ||
          "Désolé, une erreur veuillez réessayer ulterieurement",
      });
      return;
    }

    //NOT VALID FORM

    //OTHER ERROR
    else {
      displayInfoModal({
        ...infoModalConfigPreset.INTERN_ERROR,
        title: "Oupps",
        message:
          errorMessage ||
          "Désolé, une erreur veuillez réessayer ulterieurement",
      });
      return;
    }
  };

  /**
   * Handle Network Error
   * This function handle the axios error:
   *  • Manage the error
   *  • Dysplay the error to the user
   * @param error Axios error
   * @param SreenContext the screen where the error occures
   * @returns Nothing
   */
  const handleNetworkError = (
    error: AxiosError,
    SreenContext: ScreenLocationType | string | null
  ) => {
    console.log(error);
    //NO Statut
    if (!error.response?.status) {
      displayInfoModal({
        ...infoModalConfigPreset.NETWORK_PB,
        message: error.message,
      });
      return;
    }

    if (error.response?.status === StatusCodes.REQUEST_TOO_LONG) {
      displayInfoModal({
        ...infoModalConfigPreset.NETWORK_PB,
        title: "Envoi trop loud",
        message: "Veuillez choisir des pièces jointes plus légères svp. ",
      });
      return;
    }
    //UNAUTHORIZED USER CASE
    if (error.response?.status === StatusCodes.NOT_FOUND) {
      if (SreenContext === "Login") {
        return;
      }

      displayInfoModal({
        ...infoModalConfigPreset.INTERN_ERROR,
        message: error.message,
      });
      return;
    }
    if (error.response?.status === StatusCodes.UNAUTHORIZED) {
      if (SreenContext === "Login") {
        displayInfoModal({ ...infoModalConfigPreset.BAD_PASSWORD_OR_ID });
        return;
      } else {
        dispatch(logoutUserAction());
        displayInfoModal({
          ...infoModalConfigPreset.INTERN_ERROR,
          message: error.message,
        });
        return;
      }
    }

    if (error.response?.status === 403) {
      dispatch(logoutUserAction());
      displayInfoModal({ ...infoModalConfigPreset.SESSION_ENDED });
      return;
    }

    //OTHER ERROR CASE
    else {
      const responseError = error.response as any;

      displayInfoModal({
        ...infoModalConfigPreset.INTERN_ERROR,
        message: messageConverter(responseError?.data || responseError?.data?.error || error.message),
      });
      return;
    }
  };

  return {
    handleNetworkError,
    handleInternError,
  };
}

const messageConverter = (errorMessages: string | undefined | null) => {
  if (!errorMessages) {
    return "Désolé, une erreur veuillez réessayer ulterieurement";
  }

  const listCode = Object.keys(LIST_CODE_ERROR_MESSAGE);

  for (const code of listCode) {
    if (errorMessages.includes(code)) {
      return (
        LIST_CODE_ERROR_MESSAGE[code] ||
        "Désolé, une erreur veuillez réessayer ulterieurement"
      );
    }
  }

  return errorMessages;
};
