/*===================================================================
=       USE SPENDING DEMAND CORE                   =
====================================================================*/
/**
 *
 *
 */

import { AxiosError } from "axios";
import { handlerErrorFunctions } from "../../../../utils/errorHlandlerUtils";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import SpendingDemandAPI from "../../services/SpendingDemandAPI";
import {
  FilterSpendingDemand,
  SpendingDemand,
} from "../../types/spendingDemand.types";
import SpendingDemandListFilterPresenter from "../../components/SpendingDemandList/SpendingDemandListFilter/SpendingDemandListFilterPresenter";

import useSpendingDemandFilter from "../useSpendingDemandFilter";
import { useDispatch, useSelector } from "react-redux";
import { spendingDemandMapState } from "../../redux/spendingDemand.mapstate";
import {
  setSelectModeAction,
  setSpendingDemandSelectedListAction,
} from "../../redux/spendingDmeand.actions";

export type Action = "VALIDATE" | "REJECT";
export interface SpendingDemandSettings {
  handleNetworkErrorFunction:
    | ((error: AxiosError<unknown, any>, SreenContext: string | null) => void)
    | null;
  handleInternErrorFunction:
    | ((errorMessage: string | null, errorCode: number | null) => void)
    | null;
  getCurrentPage: (() => number | null) | null;

  getCurrentFilter: () => FilterSpendingDemand;

  employeeId: string;
  userId: string;
}

export const useSpendingDemandCore = ({
  handleNetworkErrorFunction,
  handleInternErrorFunction,
  getCurrentPage,
  getCurrentFilter,
  employeeId,
  userId,
}: SpendingDemandSettings) => {
  const { handleNetworkError, handleInternError } = handlerErrorFunctions(
    handleNetworkErrorFunction,
    handleInternErrorFunction
  );
  const dispatch = useDispatch();
  const {
    spendingDemand: { spendingDemandSelected, isSelectMode },
  } = useSelector(spendingDemandMapState);
  const currentFilter = getCurrentFilter();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<FilterSpendingDemand | null>(
    currentFilter
  );
  const currentPage = getCurrentPage ? getCurrentPage() : null;
  const [isLoadingActionGrouped, setIsLoadingActionGrouped] = useState(false);

  const { filter: filterV2 } = useSpendingDemandFilter();
  const queryClient = useQueryClient();

  const sendValidateSpendingDemand = useMutation<
    any,
    any,
    { message: string; spendingDemandId: string }
  >({
    mutationFn: ({
      spendingDemandId,
      message,
    }: {
      spendingDemandId: string;
      message?: string;
    }) => {
      setIsLoadingActionGrouped(true);
      return SpendingDemandAPI.validateSpendingDemandAPIProtected(
        spendingDemandId,
        message
      ).then((response) => {
        queryClient.invalidateQueries({
          queryKey: ["spendingdemand", spendingDemandId, "historic_status"],
        });
        setIsLoadingActionGrouped(false);
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["spendingdemand"] });
      queryClient.invalidateQueries({ queryKey: ["teams"] });
    },
    onError: (error) => {
      setIsLoadingActionGrouped(false);
      handleNetworkErrorFunction && handleNetworkErrorFunction(error, "spendingDemand");
      console.log("error action grouped", error);
    },
  });
  useEffect(() => {
    if (currentPage) {
      setPage(currentPage);
    }
    setFilter(filterV2);
  }, [currentPage, currentFilter, filterV2]);

  const allSpendingDemandListQuery = useQuery({
    queryFn: () =>
      SpendingDemandAPI.getFetchAllSpendingDemand(
        10,
        page,
        filter,
        userId,
        false
      ),
    queryKey: ["spendingdemand", { page, filter }],
  });

  useEffect(() => {
    if (allSpendingDemandListQuery.isError) {
      handleNetworkError(
        allSpendingDemandListQuery.error as AxiosError,
        "spendingDemandList"
      );
    }
  }, [allSpendingDemandListQuery.isError]);

  const setSpendingDemandSelectedList = (
    spendingDemandSelected: SpendingDemand[] | null
  ) => {
    dispatch(setSpendingDemandSelectedListAction(spendingDemandSelected));
  };

  const validateGroupedSpendingDemand = (
    spendingDemandSelected: SpendingDemand[] | null
  ) => {
    if (spendingDemandSelected) {
      spendingDemandSelected.forEach((spendingDemand) => {
         sendValidateSpendingDemand.mutate({
          spendingDemandId: spendingDemand.spending_demand_id,
          message: "",
        });

      }
    );
    setSpendingDemandSelectedList([]);
    }
  };
  const groupedActions = (action: Action) => {
    switch (action) {
      case "VALIDATE":
        validateGroupedSpendingDemand(spendingDemandSelected);
        break;
    }
  };

  const setSelectMode = (selectMode: boolean) => {
    dispatch(setSelectModeAction(selectMode));
  };

  const isSpendingDemandeSelected = (spendingDemandId: string) => {
    return spendingDemandSelected?.some(
      (spendingDemand) => spendingDemand.spending_demand_id === spendingDemandId
    );
  };

  const selectSpendingDemand = (spendingDemand: SpendingDemand) => {
    if (!spendingDemandSelected){
      setSpendingDemandSelectedList([spendingDemand])
      return
    }else{
      setSpendingDemandSelectedList([ ...spendingDemandSelected, spendingDemand])
    }
  }

  const unSelectSpendingDemand = (spendingDemand: SpendingDemand) => {
    if (!spendingDemandSelected) return
    setSpendingDemandSelectedList(spendingDemandSelected.filter((spending) => spending.spending_demand_id !== spendingDemand.spending_demand_id))
  }

  
  return {
    allSpendingDemandList: {
      data: allSpendingDemandListQuery.data,
      isLoading: allSpendingDemandListQuery.isLoading,
      isError: allSpendingDemandListQuery.isError,
      error: allSpendingDemandListQuery.error,
      isFetching: allSpendingDemandListQuery.isFetching,
      isSuccess: allSpendingDemandListQuery.isSuccess,
    },

    setSpendingDemandSelectedList,
    spendingDemandSelected,
    groupedActions,
    isLoadingActionGrouped,
    setSelectMode,
    isSelectMode,
    isSpendingDemandeSelected,
    selectSpendingDemand,
    unSelectSpendingDemand
  };
};

export default useSpendingDemandCore;
