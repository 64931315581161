
import SelectActiveBtn from "./SelectActiveBtn/SelectActiveBtn";
import SelectGroupedAction from "./SelectGroupedAction/SelectGroupedAction";
import SpendingDemandListComponent from "./SpendingDemandList ";
import SpendingDemandListFilterPresenter from "./SpendingDemandListFilter/SpendingDemandListFilterPresenter";
const SpendingDemandList = {
    List : SpendingDemandListComponent,
    Filter : SpendingDemandListFilterPresenter,
    SelectActiveBtn: SelectActiveBtn,
    GroupedActionBtn: SelectGroupedAction

}

export default SpendingDemandList;