import { useLocation, useNavigate, useParams } from "react-router-dom"
import useSingleTeamCore from "./core/useSingleTeamCore"
import useTeamCore from "./core/useTeamCore"
import useUserAuth from "../../auth/hook/useUserAuth";
import useErrorHandler from "../../../hook/useErrorHandler";
import { FetchTeamFilterOption } from "../services/teamAPI";


export const useTeam = () => {
    const location : string = useLocation().search;
    let  pageParams  = new URLSearchParams(location).get('page');
    const navigate = useNavigate()


    const goToPage = (page: number) => {
        
        const params = new URLSearchParams(location);
        params.set('page', page.toString())
        const path = location.split('?')[0] + '?' + params.toString()
        navigate(path)
        
     
    }
    const getCurrentPage = () => pageParams ? parseInt(pageParams) : null
     const {handleInternError, handleNetworkError}= useErrorHandler()

     const setFilterListTeam = (filter: FetchTeamFilterOption, pageCurrent?: number) => {
        const params = new URLSearchParams(location);
        
        if(pageCurrent){
            params.set('page', pageCurrent.toString())
        }

        for (const key in filter) {
            if (filter[key as keyof FetchTeamFilterOption]) {
                params.set(key, filter[key as keyof FetchTeamFilterOption] as string)
            } else {
                params.delete(key)
            }
        }
        const path = location.split('?')[0] + '?' + params.toString()
        navigate(path)
     }

     const getFilterListTeam  = () : FetchTeamFilterOption => {
        return {
            managerEmployeeId: new URLSearchParams(location).get('managerEmployeeId') || null,
            memberEmployeeId: new URLSearchParams(location).get('memberEmployeeId') || null,
            projectId: new URLSearchParams(location).get('projectId') || null,
        }
     }

    return useTeamCore(handleNetworkError, handleInternError, getCurrentPage, goToPage,  setFilterListTeam, getFilterListTeam )
}

export const useSingleTeam = (id?: string) => {
    const {teamId} =useParams()
    const teamIdFound = id || teamId || ''
    const {userInfo} = useUserAuth()
    const employeeId = userInfo?.user_employee_id || 'null'
    const {handleInternError, handleNetworkError}= useErrorHandler()
    return useSingleTeamCore(teamIdFound, handleNetworkError,handleInternError, employeeId)
}

