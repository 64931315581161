/*===================================================================
=       USER API                           =
====================================================================*/
/**
 * Manage the user data, sessions and authentication.
 */

import axios from "axios"
import { User, UserFilter } from "../types/user.types"
import { ResponseAPIWithPagination } from "../../../types/responsesAPI.types"
import { getUserFilter } from "./userAPI.utils"

const BASE_URL = process.env.REACT_APP_URL_BACKEND
/**
 * get Single user 
 * 
 * @param userId user id
 */
export const getSingleUserProtectedAPI = (userId: string) => {
    return axios.get<User>(`${BASE_URL}/user/${userId}`)
}

export const getSingleUserProtectedAPIV2 = async (userId: string) => {
  
    return (await axios.get<User>(
        `${BASE_URL}/api/v2/users/${userId}`)).data;
}
export const searchUserProtectedAPI = async (query: string, page: number = 0, pageSize = 0, filter?: UserFilter ) => {
    const queryWhere = getUserFilter({filter, })
    return (await axios.get<ResponseAPIWithPagination<User>>(
        `${BASE_URL}/api/v2/users/search?query=${query}&pageSize=${pageSize}&currentPage=${page}&whereQuery=${queryWhere}`)).data;
}



const userAPI = {
    getSingleUserProtectedAPI,
    searchUserProtectedAPI,
    getSingleUserProtectedAPIV2
}

export default userAPI
