import React, { useState } from "react";


import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import { spendingDemandStatusRawToReadableStatus } from "../../../../utils/spenpdingDemandStatusConverter";
import { SpendingDemandStatus } from "../../../../../spending/types/spending.types";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface StatusItem {
  value: SpendingDemandStatus;
  label: string;
}

export interface SpendingDemandListFilterViewProps {
  handleClickStatus: (value: string) => void,
  statusList: StatusItem[]
  currentStatusSelected: string[] | undefined | null

}
export default function SpendingDemandListFilterView({handleClickStatus, statusList, currentStatusSelected}: SpendingDemandListFilterViewProps) {

  return (
    <>
      <FormControl sx={{ m: 1, width: 300 }} >
        <InputLabel>Status</InputLabel>
        <Select
          style={{ width: "100%", zIndex: 0, position: "relative" }}
          placeholder="Statut"
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={currentStatusSelected || []}
          input={<OutlinedInput label="Status" />}
          renderValue={(selected: any) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value: string) => (
                <Chip
                  style={{ zIndex: 10, position: "relative" }}
                  key={value}
                  label={spendingDemandStatusRawToReadableStatus(value, "general").status}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {statusList.map((status) => (
            <MenuItem
              onClick={() => {
                handleClickStatus(status.value)
              }}
              key={status.label}
              value={status.value}
            >
              {status.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
